import {
	v2_Home_Clicked,
	v2_MyGoals_Clicked,
	v2_Transfer_Clicked,
	v2_MyStash_Clicked,
} from '@stashinvest/product-events-javascript/lib/v2/event_static_methods';
import {
	ArrowsLeftRightIcon,
	ChartLineIcon,
	HouseIcon,
	TargetIcon,
} from '@stashinvest/react-assets';

export const HOME_NAV_OPTION = {
	event: v2_Home_Clicked,
	banjoId: 'navigation/navigate/home',
	urlMatches: ['/$'],
	railOption: { Icon: HouseIcon, to: '/' },
};

export const MY_GOALS_NAV_OPTION = {
	event: v2_MyGoals_Clicked,
	banjoId: 'navigation/navigate/goals',
	urlMatches: ['/goals*'],
	railOption: { Icon: TargetIcon, to: '/goals' },
};

export const TRANSFER_NAV_OPTION = {
	event: v2_Transfer_Clicked,
	banjoId: 'navigation/navigate/transfer',
	urlMatches: ['/transfer*'],
	railOption: {
		Icon: ArrowsLeftRightIcon,
		to: '/transfer',
	},
};
export const MY_STASH_NAV_OPTION = {
	event: v2_MyStash_Clicked,
	banjoId: 'navigation/navigate/myStash',
	urlMatches: ['/my-stash'],
	railOption: {
		Icon: ChartLineIcon,
		to: '/my-stash',
	},
};

export const defaultNavOptions = [
	HOME_NAV_OPTION,
	MY_STASH_NAV_OPTION,
	MY_GOALS_NAV_OPTION,
	TRANSFER_NAV_OPTION,
];
