/**
 * cookies.js
 *
 * Utility functions for persisting application
 * data to cookie storage. All cookies are scoped to
 * the host APP_HOSTNAME and path '/'
 *
 */

import { APP_HOSTNAME } from '../constants';
import { addDays } from './time';

export const setCookie = (name, value, options) => {
	const expires =
		(options && options.expires) ||
		(options && options['max-age'] && getExpires(options['max-age'])) ||
		getExpires(60 * 30);
	const secureCookie = `${name}=${value}; expires=${expires}; secure=true; domain=.${
		options && options.domain ? options.domain : APP_HOSTNAME
	}; path=/`;
	const testCookie = `${name}=${value}; expires=${expires}; path=/`;
	// Set the cookie.
	document.cookie = process.env.NODE_ENV === 'test' ? testCookie : secureCookie;

	return value;
};

export const getCookie = (name) => {
	function escape(s) {
		// eslint-disable-next-line no-useless-escape
		return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1');
	}
	var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
	if (!!match) {
		if (match[1] === 'null') {
			return null;
		}
		if (match[1] === 'undefined') {
			return undefined;
		}
		return match[1];
	}

	return undefined;
};

export const deleteCookie = (name) => {
	setCookie(name, '', { 'max-age': -1, domain: 'stash.com' });
	return setCookie(name, '', { 'max-age': -1 });
};

function getExpires(maxAge) {
	// convert a maxAge to an 'expires' date.
	return new Date(Date.now() + maxAge * 1000).toUTCString();
}

export const setUTMCookies = () => {
	const SIXTY_DAYS_FROM_NOW = addDays(new Date(), 60);
	const UTMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
	const params = new URLSearchParams(window.location.search);

	params.forEach((value, key) => {
		if (UTMS.includes(key)) {
			document.cookie = `${key}=${value}; expires=${SIXTY_DAYS_FROM_NOW.toUTCString()}; secure=true; domain=.stash.com; path=/`;
		}
	});
};
